import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: {
                // Landing Page
                    // First Section 
                "bot": "ARYA",
                "Experts": "Experts",
                "Partner": "Partner",
                "Blogs": "Blogs",
                "Contact": "Contact",
                "Askbtn": "Ask Arya",
                "welcome": "Discover the timeless wisdom of the Vedic Scriptures with Arya, your spiritual guide🕉️",
                "quote": "Bridging ancient knowledge, empowering modern lives.",
                    // Second Section
                "reviewhead": "Trusted by spiritual seekers across the world",
                "review1": "Thank you for building such a beautiful platform. Tried some queries, and it looks good. Gonna send it to mom !",
                "review1name": "Prabath M.",
                "review1position": "Entrepreneur",
                "review2": "Works well! Compared to other online options for such discussions, it is more beneficial as it gives references to the part of Ved for more details.",
                "review2name": "Virat",
                "review2position": "Businessman",
                "review3": "Mokx's innovative approach to spiritual wisdom is commendable. Arya is a blessing!",
                "review3name": "Rajiv M.",
                "review3position": "Software Engineer",
                    // Third Section
                "qex1": "How does Karma impact our daily actions?",
                "qex2": "Significance of Gayatri mantra",
                "qex3": "What do Vedas recommend for good health?",
                "aryaintro": "Namaste🙏 \n Meet Arya, the world's first AI Acharya",
                "aryadetail": "Arya brings the timeless teachings of the Vedas to your fingertips, providing profound insights and spiritual guidance for your modern life. Whether you seek guidance on mantras, general life advice, or specific Vedic interpretations, Arya is here to assist you.",
                "unveilhead": "Unveil Reliable Wisdom:",
                "unveilcontent": "Detailed explanation of 4000+ mantras vetted by Vedic experts.",
                "modernhead": "Navigate Modern Life:",
                "moderncontent": "Solve modern challenges by adapting Vedic principles to present-day contexts.",
                "simplifyhead": "Simplify Spiritual Understanding:",
                "simplifycontent": "Decode complex Vedic texts, enabling easy comprehension for all seekers.",
                    // Fourth Section
                "whatsapphead": "Get Daily Mantras and quotes on WhatsApp",
                "whatsappcontent": "Discover a world of inspiration and wisdom at your fingertips. Arya, curates these messages to empower your day, infusing it with positivity and motivation. Whether you seek inner peace, personal growth, or spiritual enlightenment, our tailored content offers a personalized experience like no other.",
                "voicehead": "Voice based interaction",
                "voicecontent": "Engaging with Arya is as easy as speaking your mind. Our voice-based interaction feature allows you to send and receive audio messages effortlessly. Experience the human touch in the digital world. Interact naturally, express your thoughts with clarity, and feel connected to the Mokx community.",
                "learn": "Learn more...",
                "langhead": "Converse in your own language",
                "langcontent": "At Mokx, inclusivity is paramount. We offer the flexibility to converse in up to 10 regional Indian languages, ensuring that everyone can access and benefit from our services. No matter where you're from, your native tongue is welcome here.",
                    // Fifth Section
                "whatsappsubhead": "Get daily Vedic wisdom on Whatsapp",
                "dailym": "Daily Mantras",
                "dailymcontent": "Start your day with purpose and positivity! Receive a daily dose of uplifting mantras that will empower and motivate you to conquer life's challenges.",
                "askarya": "Ask Arya",
                "askaryacontent": "Have burning questions or seeking advice? Ask Arya, our knowledgeable AI, and get insightful answers on various topics from relationships to personal growth.",
                "personalized": "Personalized Content",
                "personalizedcontent": "Tailor your subscription to your interests! Choose from a selection of topics like family, money, peace, and more, and receive daily content curated just for you.",
                    // Sixth Section
                "patnerhead": "Partner with us 🤝",
                "patnercontent": "Are you a spiritual or wellness organization looking to expand your reach, connect with your audience on a deeper level, and harness the power of AI-driven insights?",
                "scripture": "Scripture-Powered Promotion:",
                "scripturecontent": "Arya integrates seamlessly, infusing spiritual context into your offerings for a deeper connection with your audience.",
                "ailicensing": "AI Licensing:",
                "ailicensingcontent": "Tap into Vedic AI's potential by licensing Arya's tech, fusing ancient wisdom with modern tech for unique user experiences.",
                "exclusive": "Exclusive Privileges:",
                "exclusivecontent": "Gain early access to Mokx's latest innovations, keeping your organization ahead in the spiritual and wellness industry. Join us on a journey of growth and enlightenment.",
                    // Seventh Section
                "runningtext1" : "Start your journey of enlightenment through ancient knowledge with Arya 🌟",
                "runningtext2" : "Can you explain the concept of Dharma?",
                "runningtext3" : "Does Karma get carried to my next birth?",
                "runningtext4" : "What is the first mantra of Rigveda?",
                    // Footer Section
                "abouthead": "About Us",
                "aboutcontent": "We are dedicated to bringing you closer to your Vedic roots by offering a comprehensive platform for spiritual guidance and knowledge. Our mission is to provide an immersive experience, connecting you with the profound teachings of the Vedas.",
                "linkhead": "Quick Links",
                "home": "Home",
                "privacy": "Help & Support",
                "partnerw": "Partner with us",
                "partner": "Partner",
                "contactus": "Contact Us",
                "address": "108, Saklenabad, Ghazipur, Uttar Pradesh 233001",
                "companyname": "© 2024. All Rights Reserved. KYUKEY TECHNOLOGIES PRIVATE LIMITED",
                
                // Blog Translation
                "blogwelcome": "Deep dive into the world of the Vedas",
                // Expert Translation
                "expertscrollhead": "Access our courses on Vedic Wisdom",

                // ChatBot
                "post": "Vedic Acharya",
                "Diwali": "Diwali Pooja",
                "Mantras": "Mantras",
                "Wellbeing": "Wellbeing",
                "Myths": "Myths",
                "Spirituality": "Spirituality",
                "namaste": "🙏 Namaste!  I'm Arya, your AI Vedic Acharya.  I'll help with insights from Vedas for daily life concerns. Get guidance on mantras, general life advice, or specific Vedic interpretations. You can text or ask queries in your voice. 📝🎤",
                "like": "You can ask queries, like:",
                "curiosityGuide": "Let your curiosity keep growing, you will find the solution here.",
                "blessings": "I wish you good blessings and knowledge.",
                "Welques1": "What is the place of importance of truth and non-violence in the Vedas?",
                "Welques2": "Explain about the self-nature and self-knowledge contained in the Vedas.",
                "Welques3": "How is the importance of time explained in the Vedas?",
                "Welques4": "Share insights from the Vedas about joy and happiness.",
                "placeholder": "Ask Arya",
                "rec": "Recording . . .",
                "cancelAu": "Tap to cancel",
                "DiwaliH": "Happy Diwali! 🪔\nI'm here to make your Diwali Pooja a seamless and spiritually fulfilling experience. Here's how I can assist you:",
                "MantrasH":"Certainly! Here are some mantras you can choose to know the meaning of",
                "WellbeingH": "Definitely! Here are some wellbeing related questions that you can choose to know the answers.",
                "MythsH": "Definitely! Here are some myth-related questions you can choose to debunk the myth.",
                "SpiritualityH": "Definitely! Here are some spirituality related questions that you can choose to deepen your depth in spirituality",
                "chatrunningtext1": "Thanks for your response 🙂",
                "chatrunningtext2": "I'm trying to understand your concern...",
                "chatrunningtext3": "Reading the pages of the Vedas for your response 📔",
            }
        },
        hi: {
            translation: {
                // Landing Page
                    // First Section
                "bot": "आर्या",
                "Experts": "विशेषज्ञ",
                "Partner": "सहभागी",
                "Blogs": "ब्लॉग",
                "Contact": "संपर्क",
                "Askbtn": "आर्या से पूछिए",
                "welcome": "अपनी आध्यात्मिक मार्गदर्शक आर्या के साथ वैदिक शास्त्रों के कालातीत ज्ञान की खोज करें।🕉️",
                "quote": "प्राचीन ज्ञान को जानना आधुनिक जीवन को सशक्त बनाना।",
                    // Second Section
                "reviewhead": "दुनिया भर के आध्यात्मिक जिज्ञासुओं के लिए विश्वसनीय",
                "review1": "इतना सुंदर मंच बनाने के लिए धन्यवाद। कुछ प्रश्नों का उत्तर पाने का प्रयास किया और यह अच्छा लग रहा है। इसे माँ को भेजूंगा!",
                "review1name": "प्रभात एम.",
                "review1position": "उद्यमी",
                "review2": "यह प्लेटफॉर्म अच्छी तरह से काम करता है! ऐसी चर्चाओं के लिए अन्य ऑनलाइन विकल्पों की तुलना में, यह अधिक फायदेमंद है क्योंकि यह अधिक विवरण के लिए वैदिक मंत्रों का संदर्भ देता है।",
                "review2name": "विराट",
                "review2position": "बिजनेसमैन",
                "review3": "आध्यात्मिक ज्ञान के लिए mokx का अभिनव दृष्टिकोण सराहनीय है आर्या एक आशीर्वाद है!",
                "review3name": "राजीव एम.",
                "review3position": "सॉफ्टवेयर इंजीनियर",
                    // Third Section
                "qex1": "कर्म हमारे दैनिक कार्यों को कैसे प्रभावित करते हैं?",
                "qex2": "गायत्री मंत्र का महत्व",
                "qex3": "अच्छे स्वास्थ्य के लिए वेद क्या सलाह देते हैं?",
                "aryaintro": "नमस्ते🙏 \n दुनिया की पहली एआई आचार्या आर्या से मिलिए।",
                "aryadetail": "आर्या वेदों की शाश्वत शिक्षाओं को जानना आपके लिए सरल बनाती है । यह आपके आधुनिक जीवन के लिए गहन अंतर्दृष्टि और आध्यात्मिक मार्गदर्शन प्रदान करती है। चाहे आप मंत्रों, सामान्य जीवन के लिए सलाह या विशिष्ट वैदिक व्याख्याओं पर मार्गदर्शन चाहते हों, आर्या आपकी सहायता के लिए यहां हैं।",
                "unveilhead": "विश्वसनीय बुद्धिमत्ता का अनावरण करें:",
                "unveilcontent": "वैदिक विशेषज्ञों द्वारा परीक्षित 4000+ मंत्रों की विस्तृत व्याख्या",
                "modernhead": "आधुनिक जीवन की खोज करें:",
                "moderncontent": "वैदिक सिद्धांतों को वर्तमान संदर्भों में अपनाकर आधुनिक चुनौतियों का समाधान करें।",
                "simplifyhead": "अपनी आध्यात्मिक समझ को सरल बनाएं:",
                "simplifycontent": "जटिल वैदिक ग्रंथों को इस रूप में पाएँ, जिससे सभी साधकों को आसानी से समझ में आ सकें।",
                    // Fourth Section
                "whatsapphead": "व्हाट्सएप पर दैनिक मंत्र और उद्धरण प्राप्त करें, आसानी से प्रेरणा और ज्ञान की दुनिया खोजें।",
                "whatsappcontent": "आर्या आपके दिन को अच्छा बनाने, इसे सकारात्मकता और प्रेरणा से भरने के लिए इन संदेशों को क्यूरेट करते हैं। चाहे आप आंतरिक शांति, व्यक्तिगत विकास या आध्यात्मिक ज्ञान चाहते हों, हमारे द्वारा उपलब्ध कराई गई सामग्री संतोषजनक व्यक्तिगत अनुभव प्रदान करती है।",
                "voicehead": "आवाज आधारित बातचीत",
                "voicecontent": "आर्या के साथ जुड़ना अपने मन की बात कहने जितना आसान है। हमारी ध्वनि-आधारित इंटरैक्शन सुविधा आपको आसानी से ऑडियो संदेश भेजने और प्राप्त करने की अनुमति देती है। डिजिटल दुनिया में मानवीय स्पर्श का अनुभव करें। स्वाभाविक रूप से बातचीत करें, अपने विचारों को स्पष्टता के साथ व्यक्त करें, और Mokx परिवार से जुड़ाव महसूस करें।",
                "learn": "और जानें.....",
                "langhead": "अपनी भाषा में बातचीत करें",
                "langcontent": "Mokx में अपनी भाषा में बातचीत करें, समावेशिता सर्वोपरि है। हम 10 क्षेत्रीय भारतीय भाषाओं में बातचीत करने की सुविधा प्रदान करते हैं, जिससे यह सुनिश्चित होता है कि सब हमारी सेवाओं तक पहुंच सके और उनका लाभ उठा सके। इससे कोई फर्क नहीं पड़ता कि आप कहां से हैं, आपकी मातृभाषा का यहां स्वागत है।",
                    // Fifth Section
                "whatsappsubhead": "व्हाट्सएप पर दैनिक वैदिक ज्ञान प्राप्त करें",
                "dailym": "दैनिक मंत्र",
                "dailymcontent": "अपने दिन की शुरुआत उद्देश्य और सकारात्मकता के साथ करें! प्रगतकारी मंत्रों की दैनिक खुराक प्राप्त करें जो आपको जीवन की चुनौतियों पर विजय पाने के लिए सशक्त बनाएगी और प्रेरित करेगी।",
                "askarya": "आर्या से पूछें",
                "askaryacontent": "क्या आपके पास ज्वलंत प्रश्न हैं या आप सलाह लेना चाहते हैं? हमारे एआई विशेषज्ञ आर्या से पूछें और आपसी रिश्तों से लेकर व्यक्तिगत विकास तक विभिन्न विषयों पर व्यावहारिक उत्तर प्राप्त करें।",
                "personalized": "व्यक्तिगत सामग्री के लिए सुझाव",
                "personalizedcontent": "अपनी सदस्यता को अपनी रुचियों के अनुरूप बनाएं! परिवार, धन, शांति और अन्य विषयों में से चुनें और केवल आपके लिए तैयार की गई दैनिक सामग्री प्राप्त करें।",
                    // Sixth Section
                "patnerhead": "हमारे साथ बनें भागीदार 🤝",
                "patnercontent": "क्या आप एक आध्यात्मिक या कल्याण संगठन हैं जो अपनी पहुंच का विस्तार करना चाहते हैं, अपने दर्शकों के साथ गहरे स्तर पर जुड़ना चाहते हैं और एआई-संचालित अंतर्दृष्टि की शक्ति का उपयोग करना चाहते हैं?",
                "scripture": "धर्मग्रंथ-संचालित प्रचार: ",
                "scripturecontent": "आर्या आपके दर्शकों के साथ गहरे संबंध के लिए आपकी प्रस्तुति में आध्यात्मिक संदर्भ को शामिल करते हुए सहजता से समन्वित करता है।",
                "ailicensing": "एआई लाइसेंसिंग:",
                "ailicensingcontent": "अद्वितीय उपयोगकर्ता के अनुभवों के लिए प्राचीन ज्ञान को आधुनिक तकनीक के साथ जोड़कर आर्या की तकनीक को लाइसेंस देकर वैदिक एआई की क्षमता का लाभ उठाएं।",
                "exclusive": "विशिष्ट विशेषाधिकार:",
                "exclusivecontent": "अपने संगठन को आध्यात्मिक और कल्याण उद्योग में आगे रखते हुए, Mokx के नवीनतम नवाचारों तक शीघ्र पहुंच प्राप्त करें। विकास और ज्ञानोदय की यात्रा पर हमारे साथ जुड़ें।",
                    // Seventh Section
                "runningtext1" : "आर्या के साथ प्राचीन ज्ञान के माध्यम से ज्ञानोदय की अपनी यात्रा शुरू करें 🌟",
                "runningtext2" : "क्या आप धर्म की अवधारणा को समझा सकते हैं?",
                "runningtext3" : "क्या कर्म मेरे अगले जन्म तक ले जाते हैं?",
                "runningtext4" : "ऋग्वेद का प्रथम मन्त्र क्या है?",
                    // Footer Section
                "abouthead": "हमारे बारे में",
                "aboutcontent": "हम आध्यात्मिक मार्गदर्शन और ज्ञान के लिए एक व्यापक मंच प्रदान करके आपको आपकी वैदिक जड़ों के करीब लाने के लिए समर्पित हैं। हमारा मिशन आपको वेदों की गहन शिक्षाओं से जोड़कर एक गहन अनुभव प्रदान करना है।",
                "linkhead": "त्वरित संपर्क",
                "home": "होम",
                "privacy": "सहायता और समर्थन",
                "partnerw": "हमारे साथ भागीदार बनें",
                "partner": "सहभागी",
                "contactus": "संपर्क करें",
                "address": "108, सकलेनाबाद, ग़ाज़ीपुर, उत्तर प्रदेश 233001",
                "companyname": "© 2024. सर्वाधिकार सुरक्षित. क्युके टेक्नोलॉजीज प्राइवेट लिमिटेड",

                // Blog translation
                "blogwelcome": "वेदों की दुनिया के गहराई में उतरें",
                // Expert Translation
                "expertscrollhead": "वैदिक ज्ञान पर हमारे पाठ्यक्रमों तक पहुंचें",

                // ChatBot
                "post": "वैदिक आचार्य",
                "Diwali": "दिवाली पूजा",
                "Mantras": "मंत्र",
                "Wellbeing": "स्वास्थ्य",
                "Myths": "मिथक",
                "Spirituality": "आध्यात्मिकता",
                "namaste": "🙏 नमस्ते! मैं आर्या हूँ, आपकी वैदिक एआई आचार्या। मैं वेदों के गहरे ज्ञान के माध्यम से जीवन के प्रश्नों के उत्तर पाने में आपकी मदद करूँगी। मंत्रों के गहरे अर्थ, जीवन के लिए सलाह या वैदिक व्याख्याओं के लिए मुझसे मार्गदर्शन प्राप्त करें। आप टेक्स्ट या आपकी आवाज़ में प्रश्न पूछ सकते हैं। 📝🎤",
                "like": "आप प्रश्न पूछ सकते हैं, जैसे:",
                "curiosityGuide": "आपकी जिज्ञासाओं को निरंतर बढ़ने दीजिए, आपको समाधान यहाँ मिलेगा।",
                "blessings": "आपको शुभ आशीर्वाद और ज्ञान की कामना करती हूँ।",
                "Welques1": "सत्य और अहिंसा की महत्ता का वेदों में क्या स्थान है?",
                "Welques2": "वेदों में निहित आत्म-स्वरूप और आत्म-ज्ञान के बारे में बताएं।",
                "Welques3": "वेदों में समय का महत्व किस तरह बताया गया है?",
                "Welques4": "वेदों में आनंद और सुख की बातें बताएं।",
                "placeholder": "आर्या से पूछें",
                "rec": "रिकॉर्डिंग . . .",
                "cancelAu": "रद्द करने के लिए टैप करें",
                "DiwaliH": "दीपावली की हार्दिक शुभकामनाएं! 🪔\nमैं आपकी दिवाली पूजा को एक सहज और आध्यात्मिक रूप से संतुष्टिदायक अनुभव बनाने के लिए यहां हूं। यहां बताया गया है कि मैं आपकी कैसे सहायता कर सकता हूं:",
                "MantrasH":"निश्चित रूप से! यहां कुछ मंत्र दिए गए हैं जिनका चयन आप अर्थ जानने के लिए कर सकते हैं",
                "WellbeingH": "निश्चित रूप से! यहां कुछ स्वास्थ्य सम्बंधित प्रश्न दिए गए हैं जिनका चयन आप उत्तर जानने के लिए कर सकते हैं|",
                "MythsH": "निश्चित रूप से! यहां कुछ मिथ्या सम्बंधित प्रश्न दिए गए हैं जिनका चयन आप मिथ्या का अंत करने के लिए कर सकते हैं|",
                "SpiritualityH": "निश्चित रूप से! यहां आध्यात्मिकता से संबंधित कुछ प्रश्न दिए गए हैं जिन्हें चुनकर आप आध्यात्मिकता में अपनी गहराई को और गहरा कर सकते हैं",
                "chatrunningtext1": "आपकी प्रतिक्रिया के लिए धन्यवाद 🙂",
                "chatrunningtext2": "मैं आपके प्रश्न को समझने का प्रयास कर रही हूं...",
                "chatrunningtext3": "आपकी प्रतिक्रिया के लिए वेदों के पन्ने पढ़े जा रहे हैं 📔",
            }
        }
    },
    lng: "en",
})

export default i18n;